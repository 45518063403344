'use strict';

const Constants = {
	ActionTypes: {

		Browser: {
			SET_SHOW_STATUS_BAR: 'BROWSER_SET_SHOW_STATUS_BAR',
			PRELOAD_MEDIA:       'BROWSER_PRELOAD_MEDIA',
			CLOSE_MODAL:         'BROWSER_CLOSE_MODAL',
		},

		Blog: {
			WP_BLOG_POSTS_FETCH:    'VXONE_WP_BLOG_POSTS_FETCH',
			WP_BLOG_POSTS_RECEIVED: 'VXONE_WP_BLOG_POSTS_RECEIVED',
			PM_FETCH:               'VXONE_MOBILE_BLOG_POSTS_FETCH',
			PM_RECEIVED:            'VXONE_MOBILE_BLOG_POSTS_RECEIVED',
			ARCHIVES_FETCH:         'VXONE_MOBILE_BLOG_ARCHIVES_FETCH',
			ARCHIVES_RECEIVED:      'VXONE_MOBILE_BLOG_ARCHIVES_RECEIVED',
			ARCHIVES_PM_FETCH:      'VXONE_MOBILE_BLOG_ARCHIVES_PM_FETCH',
			ARCHIVES_PM_RECEIVED:   'VXONE_MOBILE_BLOG_ARCHIVES_PM_RECEIVED',
		},

		Breadcrumb: {
			LOAD_BREADCRUMB: 'LOAD_BREADCRUMB',
		},

		Conversion: {
			CLOSE_INCENTIVE:                     'CONVERSION_CLOSE_INCENTIVE',
			LOAD_BUY_IMAGE_ALBUM_INCENTIVES:     'CONVERSION_LOAD_BUY_IMAGE_ALBUM_INCENTIVES',
			LOAD_INCENTIVES_BY_TYPE:             'CONVERSION_LOAD_INCENTIVES_BY_TYPE',
			LOAD_PAGE_LOAD_INCENTIVES:           'CONVERSION_LOAD_PAGE_LOAD_INCENTIVES',
			LOAD_VIDEO_PREVIEW_END_INCENTIVES:   'CONVERSION_LOAD_VIDEO_PREVIEW_END_INCENTIVES',
			SET_GUEST_ALREADY_SIGNED_UP:         'CONVERSION_SET_GUEST_ALREADY_SIGNED_UP',
			SET_GUEST_INFO:                      'CONVERSION_SET_GUEST_INFO',
			SET_CONVERSION_REFILL_WINDOW_HIDDEN: 'CONVERSION_SET_REFILL_WINDOW_HIDDEN',
		},

		Guest: {
			ACCEPT_CANCELLATION_OFFER:      'GUEST_ACCEPT_CANCELLATION_OFFER',
			CLOSE_DATA_PROTECTION:          'GUEST_CLOSE_DATA_PROTECTION',
			CLOSE_AGB_AND_DATA_PROTECTION:  'GUEST_CLOSE_AGB_AND_DATA_PROTECTION',
			GET_NOTIFICATIONS_SETTINGS:     'GET_NOTIFICATIONS_SETTINGS',
			NEWSLETTER_SUBSCRIBE:           'GUEST_NEWSLETTER_SUBSCRIBE',
			NEWSLETTER_UNSUBSCRIBE:         'GUEST_NEWSLETTER_UNSUBSCRIBE',
			REDEEM_BONUS:                   'GUEST_REDEEM_BONUS',
			REQUEST_GUEST_BALANCE:          'GUEST_REQUEST_GUEST_BALANCE',
			SET_AUTO_RECHARGE_TEASER_STATE: 'GUEST_SET_AUTO_RECHARGE_TEASER_STATE',
			SET_BALANCE_FORMATTED:          'GUEST_SET_BALANCE_FORMATTED',
			SET_VOUCHERS:                   'GUEST_SET_VOUCHERS',
			SET_CAN_ONECLICK:               'GUEST_SET_CAN_ONECLICK',
			SET_HAS_AVS:                    'GUEST_SET_HAS_AVS',
			SET_AVS_STATUS:                 'GUEST_SET_AVS_STATUS',
			SET_AVS_REJECT_MESSAGE:         'GUEST_SET_AVS_REJECT_MESSAGE',
			SET_HAS_WELCOME_BONUS_REDEEMED: 'GUEST_SET_HAS_WELCOME_BONUS_REDEEMED',
			SET_IS_LOCKED:                  'GUEST_SET_IS_LOCKED',
			SET_IS_VIP:                     'GUEST_SET_IS_VIP',
			SET_IS_VXSELECT:                'GUEST_SET_IS_VXSELECT',
			SET_IS_VOICECALL_ACTIVE:        'GUEST_SET_IS_VOICECALL_ACTIVE',
			SET_IS_VXTV_ABO_SUBSCRIBER:     'GUEST_SET_IS_VXTV_ABO_SUBSCRIBER',
			SET_WAS_VXTV_ABO_SUBSCRIBER:    'GUEST_SET_WAS_VXTV_ABO_SUBSCRIBER',
			SET_SHOW_DATA_PROTECTION:       'GUEST_SET_SHOW_DATA_PROTECTION',
			SEND_VIDEO_SHARE_EMAIL:         'GUEST_SEND_VIDEO_SHARE_EMAIL',
			REMOVE_REDEEMABLE_GIFTS:        'REMOVE_REDEEMABLE_GIFTS',
			SEND_FEATURE_FEEDBACK:          'SEND_FEATURE_FEEDBACK',
			START_PINCALL_VERIFICATION:     'START_PINCALL_VERIFICATION',
			VERIFY_PINCALL:                 'VERIFY_PINCALL',
			PERPARE_AVS_PAY_REQUEST:        'PERPARE_AVS_PAY_REQUEST',
			AVS_BONUS_AVAILABLE:            'AVS_BONUS_AVAILABLE',
			SET_VIDEO_LIKE:                 'VIDEO_LIKE',
			SET_VIDEO_DISLIKE:              'VIDEO_DISLIKE',
			SET_GALLERY_LIKE:               'GALLERY_LIKE',
			SET_GALLERY_DISLIKE:            'GALLERY_DISLIKE',
			BENEFITS_CODE:                  'BENEFITS_CODE'
		},

		GuestProfile: {
			GET_GUEST_PROFILE: 'GUEST_PROFILE_GET',
			SET_GUEST_PROFILE: 'GUEST_PROFILE_SET',
			UPDATE_PROPERTIES: 'GUEST_PROFILE_UPDATE_PROPERTIES',
			SAVE_PROPERTIES:   'GUEST_PROFILE_SAVE_PROPERTIES',
			DELETE_PICTURE:    'GUEST_PROFILE_DELETE_PICTURE',
		},

		Promo: {
			HAS_GUEST_NOT_REDEEMED_PROMO_CODE_INSTANCE: 'PROMO_HAS_GUEST_NOT_REDEEMED_PROMO_CODE_INSTANCE',
		},

		Messenger: {
			SELECT_CHANNEL:                 'MESSENGER_SELECT_CHANNEL',
			SELECT_CHANNEL_WITHOUT_UPDATE:  'MESSENGER_SELECT_CHANNEL_WITHOUT_UPDATE',
			UNSELECT_CHANNEL:               'MESSENGER_UNSELECT_CHANNEL',
			FILTER_ONLINE_CHANNELS:         'MESSENGER_FILTER_ONLINE_CHANNELS',
			ADD_GIFT_TO_MESSAGE:            'MESSENGER_ADD_GIFT_TO_MESSAGE',
			REMOVE_GIFT_FROM_MESSAGE:       'MESSENGER_REMOVE_GIFT_FROM_MESSAGE',
			UPDATE_RECOMMENDED_ACTORS:      'MESSENGER_UPDATE_RECOMMENDED_ACTORS',
			UPDATE_RECOMMENDED_ACTORS_GRID: 'MESSENGER_UPDATE_RECOMMENDED_ACTORS_GRID',
			SET_ALL_MESSAGES_READ:          'MESSENGER_SET_ALL_MESSAGES_READ',
			SET_RESTORE_PREVIOUS_URL:       'MESSENGER_SET_RESTORE_PREVIOUS_URL',
			SET_UNREAD_MESSAGES_COUNT:      'MESSENGER_UNREAD_MESSAGES_COUNT',
			SET_IS_CHAT_EXIT_OPEN:          'MESSENGER_SET_IS_CHAT_EXIT_OPEN',
			BUY_CHAT_TICKET:                'MESSENGER_BUY_CHAT_TICKET',
			REMOVE_CHAT_TICKET:             'MESSENGER_REMOVE_CHAT_TICKET',
		},

		Notification: {
			SEND_MESSAGE:                                 'NOTIFICATION_SEND_MESSAGE',
			SET_ALL_CHANNELS_SEEN:                        'NOTIFICATION_SET_ALL_CHANNELS_SEEN',
			CAN_ONECLICK:                                 'GUEST_CAN_ONECLICK',
			CHARGE_ONECLICK:                              'GUEST_CHARGE_ONECLICK',
			SEND_GIFT:                                    'NOTIFICATION_SEND_GIFT',
			LOAD_MORE_CHANNELS:                           'NOTIFICATION_LOAD_MORE_CHANNELS',
			LOAD_ONLINE_CHANNELS:                         'NOTIFICATION_LOAD_ONLINE_CHANNELS',
			FILTER_CHANNELS:                              'NOTIFICATION_FILTER_CHANNELS',
			ARCHIVE_CHANNEL:                              'NOTIFICATION_ARCHIVE_CHANNEL',
			MARK_NAVBAR_NOTIFICATION_AS_READ:             'NOTIFICATION_MARK_NAVBAR_NOTIFICATION_AS_READ',
			MARK_NAVBAR_NOTIFICATION_AS_SEEN:             'NOTIFICATION_MARK_NAVBAR_NOTIFICATION_AS_SEEN',
			MARK_FLOATING_NOTIFICATION_AS_READ:           'NOTIFICATION_MARK_FLOATING_NOTIFICATION_AS_READ',
			MARK_FLOATING_NOTIFICATION_AS_SEEN:           'NOTIFICATION_MARK_FLOATING_NOTIFICATION_AS_SEEN',
			REFRESH_EMAIL_PROPERTIES:                     'NOTIFICATION_REFRESH_EMAIL_PROPERTIES',
			PASSWORD_CHANGED:                             'NOTIFICATION_PASSWORD_CHANGED',
			SEND_SIGNUP_EMAIL:                            'NOTIFICATION_SEND_SIGNUP_EMAIL',
			FAVORITE_PIN_ACTOR:                           'NOTIFICATION_FAVORITE_PIN_ACTOR',
			FAVORITE_UNPIN_ACTOR:                         'NOTIFICATION_FAVORITE_UNPIN_ACTOR',
			FAVORITE_SET_PINNED_ACTOR_EMAIL_NOTIFICATION: 'NOTIFICATION_FAVORITE_SET_PINNED_ACTOR_EMAIL_NOTIFICATION',
			FAVORITE_SET_PINNED_ACTOR_SMS_NOTIFICATION:   'NOTIFICATION_FAVORITE_SET_PINNED_ACTOR_SMS_NOTIFICATION',
			FAVORITE_PIN_ALBUM:                           'NOTIFICATION_FAVORITE_PIN_ALBUM',
			FAVORITE_UNPIN_ALBUM:                         'NOTIFICATION_FAVORITE_UNPIN_ALBUM',
			FAVORITE_PIN_SEDCARDS:                        'NOTIFICATION_FAVORITE_PIN_SEDCARDS',
			FAVORITE_UNPIN_SEDCARDS:                      'NOTIFICATION_FAVORITE_UNPIN_SEDCARDS',
			FAVORITE_PIN_PICTURE:                         'NOTIFICATION_FAVORITE_PIN_PICTURE',
			FAVORITE_UNPIN_PICTURE:                       'NOTIFICATION_FAVORITE_UNPIN_PICTURE',
			REMOVE_STATUS_BAR:                            'NOTIFICATION_REMOVE_STATUS_BAR',
		},

		Actor: {
			LOAD_DATA:                              'ACTOR_LOAD_DATA',
			LOAD_PICTURES:                          'ACTOR_LOAD_PICTURES',
			LOAD_VIDEOS:                            'ACTOR_LOAD_VIDEOS',
			LOAD_VIDEO_TAGS:                        'ACTOR_LOAD_VIDEO_TAGS',
			LOAD_VIDEO_RECOMMENDATIONS_BY_ACTOR_ID: 'LOAD_VIDEO_RECOMMENDATIONS_BY_ACTOR_ID',
			LOAD_VIDEO_RECOMMENDATIONS_BY_ALBUM_ID: 'LOAD_VIDEO_RECOMMENDATIONS_BY_ALBUM_ID',
			LOAD_TOP_VIDEOS:                        'ACTOR_LOAD_TOP_VIDEOS',
			LOAD_PROMOTED_VIDEO:                    'ACTOR_LOAD_PROMOTED_VIDEO',
			LOAD_STATE:                             'ACTOR_LOAD_STATE',
			LOAD_NOTE:                              'ACTOR_LOAD_NOTE',
			LOAD_CHAT_RATINGS:                      'ACTOR_CHAT_RATINGS',
			LOADED_CHAT_RATINGS:                    'ACTOR_CHAT_RATINGS_ARE_LOADED',
			SET_PRELIMINARY_DATA:                   'ACTOR_SET_PRELIMINARY_DATA',
			SET_PROFILE_PAGE:                       'ACTOR_SET_PROFILE_PAGE',
			LOAD_INTERVIEWS:                        'ACTOR_LOAD_INTERVIEWS',
			LOADED_INTERVIEWS:                      'ACTOR_LOADED_INTERVIEWS',
			LOAD_RECOMMENDED_MODELS:                'ACTOR_LOAD_RECOMMENDED_MODELS',
			LOADED_RECOMMENDED_MODELS:              'ACTOR_LOADED_RECOMMENDED_MODELS',
			LOAD_SEO_CONTENT:                       'ACTOR_LOAD_SEO_CONTENT',
		},

		Actors: {
			LOAD_ACTORS:              'ACTORS_ACTORS',
			TOGGLE_SHOW_ONLY_PICTURE: 'TOGGLE_SHOW_ONLY_PICTURE'
		},

		VideoV2: {
			LOAD_VIDEOS: 'LOAD_VIDEOS',
		},

		Galleries: {
			LOAD_GALLERY: 'LOAD_GALLERY',
		},

		TVSeries: {
			LOAD_SERIES: 'LOAD_SERIES',
		},

		Feedback: {
			SEND_FEEDBACK: 'SEND_FEEDBACK',
		},

		Gallery: {
			BUY_ALBUM:                         'GALLERY_BUY_ALBUM',
			LOAD_SEDCARDS:                     'GALLERY_LOAD_SEDCARDS',
			GALLERY_SET_SEDCARDS_GUEST_RATING: 'GALLERY_SET_SEDCARDS_GUEST_RATING',
			UPDATE_GALLERY_DATA:               'GALLERY_UPDATE_GALLERY_DATA',
			ADD_COMMENT:                       'GALLERY_ADD_COMMENT',
			SHOW_MORE_COMMENTS:                'GALLERY_SHOW_MORE_COMMENTS',
			SET_GUEST_RATING:                  'GALLERY_SET_GUEST_RATING',
			SET_PICTURE_GUEST_RATING:          'GALLERY_SET_PICTURE_GUEST_RATING',
			LOAD_PREVIEW:                      'GALLERY_LOAD_PREVIEW',
		},

		ProSearch: {
			INIT:                       'PROSEARCH_INIT',
			SET_CATEGORY:               'PROSEARCH_SET_CATEGORY',
			INIT_SEARCH_CONDITIONS:     'PROSEARCH_INIT_SEARCH_CONDITIONS',
			CHANGE_SEARCH_CONDITION:    'PROSEARCH_CHANGE_SEARCH_CONDITION',
			CHANGE_MOOD_VALUE:          'PROSEARCH_CHANGE_MOOD_VALUE',
			DO_SEARCH:                  'PROSEARCH_DO_SEARCH',
			SET_SEARCH_AUTOSUBMIT:      'PROSEARCH_SET_SEARCH_AUTOSUBMIT',
			LOAD_PAGE:                  'PROSEARCH_LOAD_PAGE',
			SET_COLLECTION:             'PROSEARCH_SET_COLLECTION',
			SET_FILTER:                 'PROSEARCH_SET_FILTER',
			DELETE_FILTER:              'PROSEARCH_DELETE_FILTER',
			BLUR_PROPERTIES:            'PROSEARCH_BLUR_PROPERTIES',
			UNBLUR_PROPERTIES:          'PROSEARCH_UNBLUR_PROPERTIES',
			SET_ACTOR_MAP_STATE:        'PROSEARCH_SET_ACTOR_MAP_STATE',
			CHANGE_SORT_TYPE:           'PROSEARCH_CHANGE_SORT_TYPE',
			FIT_ZIP_BOUNDS:             'PROSEARCH_FIT_ZIP_BOUNDS',
			SET_SEARCH_LOCATION_BY_ZIP: 'PROSEARCH_SET_SEARCH_LOCATION_BY_ZIP',
			SET_LAST_SEARCHED_ZIP:      'PROSEARCH_SET_LAST_SEARCHED_ZIP',
			SET_GUEST_GEO_LOCATION:     'PROSEARCH_SET_GUEST_GEO_LOCATION',
		},

		SearchResult: {
			CLEAR_RESULT_FULL:      'SEARCH_RESULT_CLEAR_RESULT_FULL',
			SET_ACTOR_ONLINE_STATE: 'SEARCH_RESULT_SET_ACTOR_ONLINE_STATE',
			SET_PATTERN_FULL:       'SEARCH_RESULT_SET_PATTERN_FULL',
			SET_PATTERN_QUICK:      'SEARCH_RESULT_SET_PATTERN_QUICK',
			ACTOR_SEARCH:           'actors-search',
			MAGAZINE_SEARCH:        'magazine-search',
			VIDEOS_SEARCH:          'videos-search',
			PHOTOS_SEARCH:          'photos-search',
			SERIES_SEARCH:          'series-search',
		},

		Special: {
			LOAD_SPECIAL_EVENT_DATA:            'SPECIAL_LOAD_SPECIAL_EVENT_DATA',
			LOAD_SPECIAL_EVENTS:                'SPECIAL_LOAD_SPECIAL_EVENTS',
			LOAD_SPECIAL_VENUS_ATTACKS_REWARD:  'SPECIAL_LOAD_VENUS_ATTACKS_REWARD',
			LOAD_SPECIAL_WIESN_GAUDI_REWARD:    'SPECIAL_LOAD_WIESN_GAUDI_REWARD',
			LOAD_SPECIAL_WET_AND_WILD_REWARD:   'SPECIAL_WET_AND_WILD_REWARD',
			LOAD_SPECIAL_WIESN_BUDE_REWARD:     'SPECIAL_LOAD_WIESN_BUDE_REWARD',
			LOAD_SPECIAL_WIESN_SHOOTING_REWARD: 'SPECIAL_LOAD_WIESN_SHOOTING_REWARD',
			LOAD_SPECIAL_HALLOWEEN_REWARD:      'SPECIAL_LOAD_HALLOWEEN_REWARD',
			LOAD_SPIT_OR_SWALLOW_CONFIG:        'LOAD_SPIT_OR_SWALLOW_CONFIG',
			LOAD_SPECIAL_REWARD_BOX_REWARD:     'SPECIAL_LOAD_REWARD_BOX_REWARD',
			SET_ACTOR_ONLINE_STATE:             'SPECIAL_SET_ACTOR_ONLINE_STATE',
			SET_GAME_POINTS:                    'SPECIAL_SET_GAME_POINTS',
			SET_LAST_ON_TOP_INACTIVE_BY_CODE:   'SPECIAL_SET_LAST_ON_TOP_INACTIVE_BY_CODE',
			SET_REWARD:                         'SPECIAL_SET_REWARD',
			SET_REWARD_HISTORY:                 'SPECIAL_SET_REWARD_HISTORY',
			SET_TRIES:                          'SPECIAL_SET_TRIES',
			COLLECT_EGG:                        'SPECIAL_COLLECT_EGG',
			SHOW_LOADER:                        'SPECIAL_SHOW_LOADER',
			HIDE_LOADER:                        'SPECIAL_HIDE_LOADER',
		},

		Survey: {
			BLOCK_SURVEY:          'SURVEY_BLOCK_SURVEY',
			CLOSE_SURVEY:          'SURVEY_CLOSE_SURVEY',
			LOAD_FEEDBACK_DATA:    'SURVEY_LOAD_FEEDBACK_DATA',
			OPEN_SURVEY:           'SURVEY_OPEN_SURVEY',
			STORE_CHAT_RATING:     'SURVEY_STORE_CHAT_RATING',
			STORE_FEEDBACK_ANSWER: 'SURVEY_STORE_FEEDBACK_ANSWER',
		},

		Teaser: {
			LOAD_STATUS_BAR: 'TEASER_LOAD_STATUS_BAR',
			LOAD_TEASERS:    'TEASER_LOAD_TEASERS',
			OPEN_TRIAL_ABO:  'TEASER_OPEN_TRIAL_ABO',
			OPEN_PAYTOUR:    'TEASER_OPEN_PAYTOUR',
			OPEN_PROMOCODE:  'TEASER_OPEN_PROMOCODE',
			OPEN_AVS:        'OPEN_AVS',
			OPEN_VIDEO:      'OPEN_VIDEO',
			OPEN_SIGNUP:     'TEASER_OPEN_SIGNUP',
			OPEN_VOICECALL:  'TEASER_OPEN_VOICECALL',
			OPEN_MODALBOX:   'TEASER_OPEN_MODALBOX',
		},

		TileGrid: {
			LOAD_GRID:                   'TILE_GRID_LOAD_GRID',
			LOAD_VIDEO_ALBUM_PICTURES:   'LOAD_VIDEO_ALBUM_PICTURES',
			RESET_BACK_LINK:             'RESET_BACK_LINK',
			RESET_SCROLL_POSITION:       'RESET_SCROLL_POSITION',
			SET_BACK_LINK:               'SET_BACK_LINK',
			SET_RESTORE_SCROLL_POSITION: 'SET_RESTORE_SCROLL_POSITION',
			SET_SCROLL_POSITION:         'SET_SCROLL_POSITION',
			SET_TILE_GRID_KEY:           'SET_TILE_GRID_KEY',
			SET_TILE_GRID_MODE:          'SET_TILE_GRID_MODE',
			SET_FILTER_LIST:             'SET_FILTER_LIST',
			TOGGLE_CROP:                 'TOGGLE_CROP',
		},

		TV: {
			CHECK_ACTORS:                         'TV_CHECK_ACTORS',
			LOAD_FORMAT_DATA:                     'TV_LOAD_FORMAT_DATA',
			LOAD_PROGRAM_INFO:                    'TV_LOAD_PROGRAM_INFO',
			LOAD_STREAM_INFO:                     'TV_LOAD_STREAM_INFO',
			LOAD_POST_DATA:                       'TV_LOAD_POST_DATA',
			LOAD_VIDEO_DATA:                      'TV_LOAD_VIDEO_DATA',
			LOAD_SUGGEST_VIDEO_DATA:              'LOAD_SUGGEST_VIDEO_DATA',
			LOAD_MEDIATHEK_VIDEO_RECOMMENDATIONS: 'LOAD_MEDIATHEK_VIDEO_RECOMMENDATIONS',
			LOAD_MEDIATHEK_VIDEOS:                'LOAD_MEDIATHEK_VIDEOS',
		},

		VXMobile: {
			ADD_CONTAINER_SCROLL_LISTENER:    'VXMOBILE_ADD_CONTAINER_SCROLL_LISTENER',
			CLICK_BEFORE_INSTALL_PROMPT:      'VXMOBILE_CLICK_BEFORE_INSTALL_PROMPT',
			CLOSE_MENU:                       'VXMOBILE_CLOSE_MENU',
			CLOSE_SUBMENU:                    'VXMOBILE_CLOSE_SUBMENU',
			DISABLE_BODY_SCROLL:              'VXMOBILE_DISABLE_BODY_SCROLL',
			ENABLE_BODY_SCROLL:               'VXMOBILE_ENABLE_BODY_SCROLL',
			GUEST_STORE_TILE_GRID_SETTINGS:   'VXMOBILE_GUEST_STORE_TILE_GRID_SETTINGS',
			HIDE_NAVBAR:                      'VXMOBILE_HIDE_NAVBAR',
			CONTENT_SCROLL:                   'VXMOBILE_CONTENT_SCROLL',
			IGNORE_NEXT_SCROLL:               'VXMOBILE_IGNORE_NEXT_SCROLL',
			REMOVE_CONTAINER_SCROLL_LISTENER: 'VXMOBILE_REMOVE_CONTAINER_SCROLL_LISTENER',
			RESTORE_NAVBAR_STATE:             'VXMOBILE_RESTORE_NAVBAR_STATE',
			SCROLL_PAGE_TO:                   'VXMOBILE_SCROLL_PAGE_TO',
			SET_ROUTE:                        'VXMOBILE_SET_ROUTE',
			SET_RESTORE_NAVBAR_STATE:         'VXMOBILE_SET_RESTORE_NAVBAR_STATE',
			SET_SCROLL_TO_ON_MOUNT:           'VXMOBILE_SET_SCROLL_TO_ON_MOUNT',
			SHOW_NAVBAR:                      'VXMOBILE_SHOW_NAVBAR',
			STORE_NAVBAR_STATE:               'VXMOBILE_STORE_NAVBAR_STATE',
			TOGGLE_MENU:                      'VXMOBILE_TOGGLE_MENU',
			TOGGLE_SUBMENU:                   'VXMOBILE_TOGGLE_SUBMENU',
			UPDATE_SUBMENU:                   'VXMOBILE_UPDATE_SUBMENU',
		},

		VXPay: {
			REDIRECT_TO:   'VXPAY_REDIRECT_TO',
			SHOW_PAYTOUR:  'VXPAY_SHOW_PAYTOUR',
			SHOW_SETTINGS: 'VXPAY_SHOW_SETTINGS',
			SHOW_VXTVABO:  'VXPAY_SHOW_VXTVABO',
		},

		Contest: {
			ADD_MEDIA_VOTING:                    'CONTEST_ADD_MEDIA_VOTING',
			DELETE_MEDIA_VOTING:                 'CONTEST_DELETE_MEDIA_VOTING',
			LOAD_NEXT_CONTESTS:                  'CONTEST_LOAD_NEXT_CONTESTS',
			LOAD_LAST_CONTESTS:                  'CONTEST_LOAD_LAST_CONTESTS',
			LOAD_CONTEST_HEADER:                 'CONTEST_LOAD_CONTEST_HEADER',
			LOAD_CONTEST_GRID_ITEMS:             'CONTEST_LOAD_CONTEST_GRID_ITEMS',
			SET_SHOULD_OPEN_MEDIA:               'CONTEST_SET_SHOULD_OPEN_MEDIA',
			SET_SHOULD_FORWARD_TO_PROFILE_VIDEO: 'CONTEST_SET_FORWARD_TO_PROFILE_VIDEO',
			SET_SHOULD_OPEN_NEXT_CONTESTS_MODAL: 'CONTEST_SET_SHOULD_OPEN_NEXT_CONTESTS_MODAL',
			SET_SHOULD_OPEN_AVS_MODAL:           'CONTEST_SET_SHOULD_OPEN_AVS_MODAL',
		},

		NavBarSearch: {
			FIELD_FOCUS: 'NAVBAR_SEARCH_FIELD_FOCUS',
			FIELD_BLUR:  'NAVBAR_SEARCH_FIELD_BLUR',
		},

		MessengerGift: {},

		Feed: {
			TOOGLE_OVERLAY:          'TOOGLE_OVERLAY',
			SET_LIKE:                'SET_LIKE',
			TRIGGER_UPDATE_POST:     'TRIGGER_UPDATE_POST',
			TRIGGER_SET_POSTS:       'TRIGGER_SET_POSTS',
			TOGGLE_BLOCK_VIDEO_PLAY: 'TOGGLE_BLOCK_VIDEO_PLAY',
			TOGGLE_VIDEO_PLAY:       'TOGGLE_VIDEO_PLAY',
		}
	},

	ApiTypes: {

		Notification: {

			Outgoing: {
				REQUEST_SESSION:                              'requestSession',
				SEND_MESSAGE:                                 'sendMessage',
				SET_ALL_CHANNELS_SEEN:                        'allChannelsSeen',
				CAN_ONECLICK:                                 'canOneClick',
				CHARGE_ONECLICK:                              'chargeOneClick',
				LOAD_CHANNELS:                                'loadChannels',
				LOAD_ONLINE_CHANNELS:                         'loadOnlineChannels',
				FILTER_CHANNELS:                              'filterChannels',
				ARCHIVE_CHANNEL:                              'archiveChannel',
				MARK_NAVBAR_NOTIFICATION_AS_READ:             'navbarNotificationMarkAsRead',
				MARK_NAVBAR_NOTIFICATION_AS_SEEN:             'navbarNotificationMarkAsSeen',
				REQUEST_NAVBAR_NOTIFICATIONS:                 'navbarNotificationsRequestNotifications',
				MARK_FLOATING_NOTIFICATION_AS_READ:           'floatingNotificationMarkAsRead',
				MARK_FLOATING_NOTIFICATION_AS_SEEN:           'floatingNotificationMarkAsSeen',
				REQUEST_FLOATING_NOTIFICATIONS:               'floatingNotificationsRequestNotifications',
				REFRESH_EMAIL_PROPERTIES:                     'refreshEmailProperties',
				PASSWORD_CHANGED:                             'passwordChanged',
				SEND_SIGNUP_EMAIL:                            'sendSignupEmail',
				FAVORITE_PIN_ACTOR:                           'favoritePinActor',
				FAVORITE_UNPIN_ACTOR:                         'favoriteUnpinActor',
				FAVORITE_SET_PINNED_ACTOR_EMAIL_NOTIFICATION: 'favoriteSetPinnedActorEmailNotification',
				FAVORITE_SET_PINNED_ACTOR_SMS_NOTIFICATION:   'favoriteSetPinnedActorSMSNotification',
				FAVORITE_PIN_ALBUM:                           'favoritePinAlbum',
				FAVORITE_UNPIN_ALBUM:                         'favoriteUnpinAlbum',
				FAVORITE_PIN_SEDCARDS:                        'favoritePinSedcards',
				FAVORITE_UNPIN_SEDCARDS:                      'favoriteUnpinSedcards',
				FAVORITE_PIN_PICTURE:                         'favoritePinPicture',
				FAVORITE_UNPIN_PICTURE:                       'favoriteUnpinPicture',
				FAVORITE_REQUEST_ACTORS:                      'favoriteRequestActors',
				PING:                                         'ping',
				REMOVE_STATUS_BAR:                            'removeStatusBar',
			},

			Incoming: {
				SESSION_ACKNOWLEDGE:              'ackSession',
				SESSION_EXPIRED:                  'sessionExpired',
				MESSAGE_PRICE:                    'messagePrice',
				CHANNELS:                         'channels',
				CHANNEL:                          'channel',
				ALL_CHANNELS_SEEN:                'allChannelsSeen',
				CAN_ONECLICK_RESULT:              'canOneClickResult',
				CHARGE_ONECLICK_RESULT:           'chargeOneClickResult',
				DATA_PROTECTION:                  'dataProtection',
				FAVORITE_ACTOR_PINNED:            'FavoriteActorPinned',
				FAVORITE_ACTOR_UNPINNED:          'FavoriteActorUnpinned',
				FAVORITE_PICTURE_PINNED:          'FavoritePicturePinned',
				FAVORITE_PICTURE_UNPINNED:        'FavoritePictureUnpinned',
				FAVORITE_ALBUM_PINNED:            'FavoriteAlbumPinned',
				FAVORITE_ALBUM_UNPINNED:          'FavoriteAlbumUnpinned',
				FAVORITE_SEDCARDS_ALBUM_PINNED:   'FavoriteSedcardsAlbumPinned',
				FAVORITE_SEDCARDS_ALBUM_UNPINNED: 'FavoriteSedcardsAlbumUnpinned',
				FAVORITE_ACTORS:                  'FavoriteActors',
				GUEST_BALANCE:                    'guestBalance',
				GUEST_VOUCHERS:                   'guestVouchers',
				NAVBAR_NOTIFICATIONS:             'navbarNotifications',
				FLOATING_NOTIFICATIONS:           'floatingNotifications',
				ACTOR_ONLINE:                     'actorOnline',
				ACTOR_OFFLINE:                    'actorOffline',
				ACTOR_SETTINGS:                   'actorSettings',
				AVS_REVOKED:                      'avsRevoked',
				AVS_SET:                          'avsSet',
				AVS_PENDING:                      'avsPending',
				AVS_REJECTED:                     'avsRejected',
				GUEST_LOCKED:                     'guestLocked',
				CHANNEL_ARCHIVED:                 'channelArchived',
				SEND_SIGNUP_EMAIL_RESULT:         'sendSignupEmailResult',
				VIP_ABO_UPDATE:                   'vipAboUpdate',
				VXTV_ABO_UPDATE:                  'vxtvAboUpdate',
				VOICECALL_START:                  'voicecallStart',
				VOICECALL_STOP:                   'voicecallStop',
				WELCOME_BONUS_REDEEMED:           'welcomeBonusRedeemed',
				FREE_SHOW_START:                  'freeShowStart',
				FREE_SHOW_STOP:                   'freeShowStop',
				PONG:                             'pong',
				PAGE_RELOAD:                      'pageReload',
				ON_GUEST_EMAIL_VERIFIED:          'onGuestEmailVerified',
				MESSENGER_BOUGHT_GIFTS_UPDATE:    'messengerBoughtGiftsUpdate',
				FREE_CHAT_DURATION_CHANGED:       'freeChatDurationChanged',
				CLASH_OF_CAMS_VOTING:             'clashOfCamsVoting',
				LIVE_REACTION:                    'liveReaction',
				SERVICE0900_STATUS_CHANGED:       'service0900StatusChanged',
				MESSENGER_CONTENT_CONTENT_CHANGE: 'messengerContentChange',
				PRIVATE_TICKET_SHOW_INVITATION:   'privateTicketShowInvitation',
				CHAT_END:                         'chatEnd',
				LOYALTY_POINTS_CHANGED:           'loyaltyPointsChanged',
				BONUS_CODE_REDEEMED:              'bonusCodeRedeemed',
			},

			MessageTypes: {
				GIFT:                   'gift',
				NEW_GIFT:               'new_gift',
				IMAGE:                  'image',
				SYSTEM:                 'system',
				TEXT:                   'text',
				NOTIFICATION:           'notification',
				IMAGE_SENT:             'image_sent',
				IMAGE_RECEIVED:         'image_received',
				CHARGE_SUCCESS:         'charge_success',
				CHARGE_FAILURE:         'charge_failure',
				CHAT_START:             'chat_start',
				CHAT_END:               'chat_end',
				VOYEUR_INFO:            'voyeur_info',
				DILDOCONTROL_AVAILABLE: 'dildocontrol_available',
				DILDOCONTROL_ACTIVE:    'dildocontrol_active',
				DILDOCONTROL_COMPLETED: 'dildocontrol_completed',
				DILDOCONTROL_STARTED:   'dildocontrol_started',
				DILDOCONTROL_FINISHED:  'dildocontrol_finished',
			},

			SenderTypes: {
				ACTOR:  'actor',
				GUEST:  'guest',
				SYSTEM: 'system',
			},

		},

	},

	ActorSearch: {
		DEFAULT_CATEGORY:          3,
		NEWLY_REGISTERED_CATEGORY: 88,
		CAMS_NEXT_DOOR:            'cams_next_door',

		SearchTypes: {
			SEARCH_TYPE_DEFAULT:   'default',
			SEARCH_TYPE_CUSTOM:    'custom',
			SEARCH_TYPE_LOCATION:  'location',
			SEARCH_TYPE_NEW_GIRLS: 'newGirls',
		},
		SortTypes:   {
			SEARCH_SORT_TYPE_RANK:       'rank',
			SEARCH_SORT_TYPE_REGISTERED: 'registered',
			SEARCH_SORT_TYPE_DISTANCE:   'distance',
		},
	},

	ActorGender: {
		NONE:   'N',
		MALE:   'M',
		FEMALE: 'F',
		COUPLE: 'C',
	},

	AvsStatus: {
		PENDING:  'pending',
		REJECTED: 'rejected',
		ACCEPTED: 'accepted',
		UNKNOWN:  'unknown',
	},

	Browsers: {
		Name: {
			CHROME:  'Chrome',
			EDGE:    'Microsoft Edge',
			FIREFOX: 'Firefox',
			IE:      'IE',
			SAFARI:  'Safari',
		},
		OS:   {
			ANDROID: 'Android',
			IOS:     'iOS',
		},
	},

	Breadcrumb: {
		ACTOR_PROFILE_OVERVIEW:          'ActorProfileOverview',
		ACTOR_PROFILE_PICTURES_DETAILS:  'ActorProfilePicturesDetails',
		ACTOR_PROFILE_PICTURES_OVERVIEW: 'ActorProfilePicturesOverview',
		ACTOR_PROFILE_VIDEO_DETAILS:     'ActorProfileVideoDetails',
		ACTOR_PROFILE_LIVE_SHOW:         'ActorProfileLiveShow',
		ACTOR_PROFILE_VIDEOS_OVERVIEW:   'ActorProfileVideosOverview',
		ACTOR_PROFILE_TOP_CAMGIRLS:      'ActorProfileTopCamgirls',
		BLOG_ARTICLE:                    'BlogArticle',
		BLOG_OVERVIEW:                   'BlogOverview',
		CATEGORY_DETAILS:                'CategoryDetails',
		CATEGORY_OVERVIEW:               'CategoryOverview',
		CAMS_NEXT_DOOR:                  'CamsNextDoor',
		CAMS:                            'Cams',
		MAGAZINE_CONTESTS_OVERVIEW:      'MagazineContestsOverview',
		MAGAZINE_MAGAZINES_OVERVIEW:     'MagazineMagazinesOverview',
		MYVISITX:                        'MyVisitX',
		MYVISITX_BOUGHT_PHOTOS:          'MyVisitXBoughtPhotos',
		MYVISITX_BOUGHT_VIDEOS:          'MyVisitXBoughtVideos',
		MYVISITX_CHATS:                  'MyVisitXChats',
		MYVISITX_FAVORITE_PHOTOS:        'MyVisitXFavoritePhotos',
		MYVISITX_FAVORITE_VIDEOS:        'MyVisitXFavoriteVideos',
		MYVISITX_FAVORITES_OVERVIEW:     'MyVisitXFavoritesOverview',
		MYVISITX_FAVORITES_PHOTOS:       'MyVisitXFavoritesPhotos',
		MYVISITX_FAVORITES_VIDEOS:       'MyVisitXFavoritesVideos',
		MYVISITX_GUEST_PROFILE:          'MyVisitXGuestProfile',
		MYVISITX_MESSENGER:              'MyVisitXMessenger',
		MYVISITX_PHOTOS:                 'MyVisitXPhotos',
		MYVISITX_PINNED_PHOTOS:          'MyVisitXPinnedPhotos',
		MYVISITX_PINNED_VIDEOS:          'MyVisitXPinnedVideos',
		MYVISITX_VIDEOS:                 'MyVisitXVideos',
		MYVISITX_SPINWHEEL:              'MyVisitXSpinwheel',
		TOP_CAMS_BEST_CHATS:             'TopCamsBestChats',
		TOP_CAMS_HD_CAMS:                'TopCamsHDCams',
		TOP_CAMS_TOP_100:                'TopCamsTop100',
		TOP_CAMS_TOP_FAVORITES:          'TopCamsTopFavorites',
		TV_GIRLS:                        'TVGirls',
		TV_LATE_NIGHT:                   'TVLateNight',
		TV_LIVESTREAM:                   'TVLivestream',
		TV_MEDIA:                        'TVMedia',
		TV_MEDIA_SERIES_OVERVIEW:        'TVMediaSeriesOverview',
		TV_MEDIA_VIDEOS_OVERVIEW:        'TVMediaVideosOverview',
		TV_PREMIUM:                      'TVPremium',
		TV_PREMIUM_LIVESTREAM:           'TVPremiumLivestream',
		TV_PREMIUM_PRICETABLE:           'TVPremiumPricetable',
		TV_PROGRAM:                      'TVProgram',
		TV_MEDIA_SINGLE_VIDEO:           'TVMediaSingleVideo',
	},

	Relations: {
		Subjects:   {
			GUEST: 1,
		},
		Predicates: {
			HAS_SEEN:       1,
			HAS_SUBSCRIBED: 2,
		},
		Objects:    {
			STORY:             1,
			TEASER:            2,
			NEWSLETTER:        3,
			MOBILE_NAVIGATION: 4,
			MODALBOX:          5,
			LEXY_VALENTINE:    6,
			BENEFITS_POPUP:    7,
		},
	},

	Blog: {
		CategoryAlias: {
			EVENT:      'events',
			ACTIONS:    'actions',
			NEWS:       'news',
			INTERVIEWS: 'interviews',
			LATEST:     'latest',
		},
		CategoryIds:   {
			ALLGEMEIN:  1,
			EVENT:      38,
			LIVE:       39,
			PREMIUM_TV: 40,
			FREE_TV:    41,
			ACTIONS:    43,
			PRODUCTION: 44,
			NEWS:       45,
			INTERVIEWS: 46,
			FEATURED:   506,
		},
		PageSize:      {
			default:  3,
			archives: 9,
		},
	},

	ChannelFilterTypes: {
		NEWEST:   'newest',
		ARCHIVED: 'archived',
		UNREAD:   'unread',
		BLOCKED:  'blocked',
	},

	TicketCreationType: {
		MANUAL:           'M',
		CHAT_TICKET:      'C',
		PURCHASED_TICKET: 'P',
	},

	ChatAbilities: {
		AUDIO:    'audio',
		CHARGE:   'charge',
		SINGLE:   'single',
		TEXT:     'text',
		TIP:      'tip',
		UPSTREAM: 'upstream',
		VIDEO:    'video',
	},

	ChatAutoPlayModes: {
		MUTED_AUTO_PLAY:    'map',
		MUTED_PROMISE_PLAY: 'mpp',
	},

	ChatErrors: {
		START_REQUEST_ALREADY_IN_PROGRESS:   'Already in progress',
		START_REQUEST_OFFLINE:               'Guest offline',
		START_REQUEST_TIMEOUT:               'Timeout',
		START_REQUEST_WRONG_SEQUENCE_NUMBER: 'Wrong sequence number',
	},

	ChatSettings: {
		BAD_CONN_MAX_RESOLUTION:         100,
		BAD_CONN_MAX_RESOLUTION_DESKTOP: 200,
		BAD_CONN_TIMEOUT_WAITING:        5000,
		HLS_NATIVE_RECOVER_TIMEOUT:      2000,
		MAX_ATTEMPTS:                    2,
		MAX_ATTEMPTS_CHAT_START:         2,
		MAX_ATTEMPTS_CHAT_START_PREVIEW: 1,
		MIN_DURATION_FOR_CHAT_EXIT:      30,
		TIMEOUT_CONNECTION_WARNING:      500,
		TIMEOUT_FLASH_DETECTION:         10000,
		TIMEOUT_FLASH_INIT:              500,
		TIMEOUT_RETRY_STREAM_START:      5000,
		TIMEOUT_RETRY_CHAT_START:        3000,
		TIMEOUT_VOLUME_BUTTON_HINT:      10000,
	},

	ContestSubTypes: {
		NOT_VOTED: 'notvoted',
		OVERVIEW:  'overview',
		TOP_10:    'top10',
		VOTED:     'voted',
		ACTOR:     'actor',
	},

	ContestLastContestsSubTypes: {
		OVERVIEW:       'overview',
		VIDEO_CONTESTS: 'videoContests',
		PHOTO_CONTESTS: 'photoContests',
	},

	/**
	 * @see EnumEventTypes
	 */
	ConversionEventTypes: {
		BUY_IMAGE_ALBUM_OPENED: 'buyImageAlbumOpened',
		CLEAR:                  'clear',
		PAGE_LOAD:              'pageLoad',
		TIME:                   'time',
		VIDEO_PREVIEW_ENDED:    'videoPreviewEnded',
	},

	/**
	 * @see EnumGuestInfoTypes
	 */
	ConversionGuestInfoTypes: {
		GUEST_ALREADY_SIGNED_UP: 'GUEST_ALREADY_SIGNED_UP',
	},

	/**
	 * @see EnumIncentiveRegions
	 */
	ConversionIncentiveRegions: {
		EXIT_INTENT:          'exitIntent',
		EXIT_INTENT_EXTENDED: 'exitIntentExtended',
		EXIT_INTENT_LOGOUT:   'exitIntentLogout',
		OVERLAY_FULL_SIZE:    'overlayFullSize',
		OVERLAY:              'overlay',
		SIDE_LEFT:            'sideLeft',
		SIDE_RIGHT:           'sideRight',
		VIDEO_PLAYER:         'videoPlayer',
		IN_PAGE:              'inPage',
	},

	/**
	 * @see EnumIncentiveTypes
	 */
	ConversionIncentiveTypes: {
		BALANCE_REFILL_COUPON:   'BALANCE_REFILL_COUPON',
		EVENT_EASTER_EGG_SEARCH: 'EVENT_EASTER_EGG_SEARCH',
		EXIT_INTENT_DEFAULT:     'EXIT_INTENT_DEFAULT',
		EXIT_INTENT_VIDEO:       'EXIT_INTENT_VIDEO',
		EXIT_INTENT_VIP:         'EXIT_INTENT_VIP',
		EXIT_INTENT_VIDEO_BUY:   'EXIT_INTENT_VIDEO_BUY',
		IMAGE_ALBUM_BUY:         'IMAGE_ALBUM_BUY',
		SALE_BONUS_COUNTER:      'SALE_BONUS_COUNTER',
		SIDE_SIGNUP_FREE_VIDEO:  'SIDE_SIGNUP_FREE_VIDEO',
		VIDEO_PREVIEW_AVS:       'VIDEO_PREVIEW_AVS',
		VIDEO_PREVIEW_BUY:       'VIDEO_PREVIEW_BUY',
		VIDEO_PREVIEW_LOGIN:     'VIDEO_PREVIEW_LOGIN',
		VIDEO_PREVIEW_PAYMENT:   'VIDEO_PREVIEW_PAYMENT',
		VIDEO_PREVIEW_SALE:      'VIDEO_PREVIEW_SALE',
		VIDEO_PREVIEW_SIGNUP:    'VIDEO_PREVIEW_SIGNUP',
		VIDEO_PREVIEW_VIP:       'VIDEO_PREVIEW_VIP',
		LUCY_CAT_VIDEO:          'LUCY_CAT_VIDEO',
	},

	CookieNames: {
		BEFORE_INSTALL_PROMPT:         'hbip',
		BLOCK_INCENTIVE_GROUP:         'blig',
		COOKIEBANNER_ACCEPTED:         'cookiebanner-accepted',
		COOKIES_BLOCKED:               'cookiesBlocked',
		FORCED_AVS:                    'favs',
		HAS_USER_SEEN_WELCOME_BANNER:  'huswb',
		STATUS_BAR_CANCELLATION_OFFER: 'sbcanoff',
		STATUS_BAR_EMAIL_VERIFIED:     'sbemav',
		STATUS_BAR_MONTHLY_BONUS:      'sbmbns',
		STATUS_BAR_NOAVS:              'sbnavs',
		TEASER_SHOW:                   'tes',
		WM_ID:                         'w',
		WM_SUBREF:                     'ws',
		WM_TOKEN:                      'wt',
	},

	CookieCategories: {
		NECESSARY:  'necessary',
		FUNCTIONAL: 'functional',
		ANALYTICS:  'analytics',
	},

	CookieSameSiteAttributes: {
		STRICT: 'Strict',
		LAX:    'Lax',
		NONE:   'None',
	},

	Currency: {
		EUR: 'EUR',
		CHF: 'CHF',
		VOC: 'Credits',
		VOU: 'Voucher',
        VXC: 'VXC',
	},

	CurrencySymbol: {
		EUR: '€',
		CHF: 'CHF',
		VOC: 'Credits',
		VOU: 'Voucher',
        VXC: 'VXC',
	},

	DeviceTypes: {
		DESKTOP: 'desktop',
		MOBILE:  'mobile',
	},

	EntryPage: {
		SEXIEST_SELFIES_WM_ID:     1,
		SEXIEST_SELFIES_WM_SUBREF: '1801002',

		Ids: {
			ROXX_RACING:       1,
			SEXIEST_SELFIES:   2,
			SPIT_OR_SWALLOW:   3,
			VALENTINE_2018:    4,
			ADWORDS_STARTPAGE: 5,
		},
	},

	Guest: {
		Restrictions: {
			NEED_LOGIN:    'needLogin',
			NEED_AVS:      'needAgeCheck',
			NEED_ABO:      'needAbo',
			NEED_PURCHASE: 'needPurchase',
		},
	},

	LocalItemNames: {
		GLOBAL_SEARCH_REQUESTS: 'globalSearchLastRequests',
	},

	TIMELINE_NAVIGATION: {
		BLOCKS: {
			FAVORITE:     "Favorite",
			TOP:          "TOP",
			CAMSHOWS:     "CAMSHOWS",
			DAILYBONUS:   "Dailybonus",
			VIP30:        "VIP30",
			FEED:         "Feed",
			TRENDS:       "Trends",
			VIDEOSTATION: "Videostation",
			NEW:          "New",
			CONTESTS:     "Contests",
			WELCOMECLIPS: "WelcomeClips",
			CATEGORIES:   "Categories",
			BLOG:         "Blog",
			TV:           "TV",
		},
	},

	Grid2: {
		BREAKPOINTS: {
			XS: 'xs',
			SM: 'sm',
			MD: 'md',
			LG: 'lg',
			XL: 'xl'
		},

		BREAKPOINTS_SMALL_TO_LARGE: ['xs', 'sm', 'md', 'lg', 'xl'],

		BREAKPOINT_MIN_WIDTHS: {
			xs: 400,
			sm: 768,
			md: 992,
			lg: 1200,
			xl: 1368,
		},

		TilePadding: {
			NONE:        'NONE',
			TINY:        'TINY',
			SMALL:       'SMALL',
			NORMAL:      'NORMAL',
			VIP30MOBILE: 'vip30mobile',
		},

		Footer: {
			Label: {
				Styles:          {
					TITLE:      'TITLE',
					SUBTITLE:   'SUBTITLE',
					ACTOR_NAME: 'ACTOR_NAME',
				},
				FontSizeScaling: {
					NONE:           'NONE',
					THREE_QUARTERS: 'THREE_QUARTERS',
					HALF:           'HALF',
				},
			},
		},

		TextBadge: {
			Styles: {
				DEFAULT:   'DEFAULT',
				SUCCESS:   'SUCCESS',
				HIGHLIGHT: 'HIGHLIGHT',
				SPECIAL:   'SPECIAL',
				GOLD:      'GOLD',
				SHAPE:     'SHAPE',
			},

			FontColors: {
				WHITE:     'WHITE',
				BLACK:     'BLACK',
				SUCCESS:   'SUCCESS',
				HIGHLIGHT: 'HIGHLIGHT',
				SPECIAL:   'SPECIAL',
			},
		},

		Ribbons: {
			TRENDING: 'TRENDING',
		},

	},

	HistoryStateKeys: {
		INITIAL_PAGE:            'initialPage',
		RESTORE_SCROLL_POSITION: 'restoreScrollPosition',
	},

	Interviews: {
		IMAGE_SIZE_1080: 'h1080',
		IMAGE_SIZE_720:  'h720',
		IMAGE_SIZE_480:  'h480',
		IMAGE_SIZE_360:  'h360',
		IMAGE_SIZE_240:  'h240',
		IMAGE_SIZE_120:  'h120',
		IMAGE_SIZE_60:   'h60',
		DURATION_MIN:    'min',
		DURATION_SEC:    'sec',
	},

	HttpMethods: {
		GET:  'GET',
		HEAD: 'HEAD',
		POST: 'POST',
		PUT:  'PUT',
	},

	Languages: {
		DE: 'de',
		EN: 'en',
		NL: 'nl',
		ES: 'es'
	},

	MediaTypes: {
		PHOTO_ALBUM: 'photo_album',
		VIDEO:       'video',
	},

	MessengerGifts: {
		DILDOCONTROL_INTENSITY_LOW:    96481,
		DILDOCONTROL_INTENSITY_MEDIUM: 96482,
		DILDOCONTROL_INTENSITY_HIGH:   96483,
		DILDOCONTROL_INTENSITY_RANDOM: 96484,
	},

	MessengerGiftCategories: {
		DILDOCONTROL: 96479,
	},

	MinimumBalanceRequiredForChat: 0.5,

	PromoCodes: {
		BONUS_TILE:            'NEU50WELCOME',
		CONVERSION_CHAT_TRIAL: 'CONVCHATTRIAL',
	},

	// see MailSubscriptionEnum
	NewsletterTypes: {
		VIPCLUB:    1024,
		LATE_NIGHT: 512,
		VRPORN:     2048,
		CONTESTS:   256,
	},

	Placeholders: {
		Avatar: {
			FEMALE:  'https://vx.vxcdn.org/public/vxteaser/placeholder/placeholder_female.jpg',
			MALE:    'https://vx.vxcdn.org/public/vxteaser/placeholder/placeholder_male.jpg',
			DIVERSE: 'https://vx.vxcdn.org/public/vxteaser/placeholder/placeholder_diverse.jpg',
		}
	},

	Ratings: {
		AlbumDislike: 1,
		AlbumLike:    5,
	},

	RegExp: {
		EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
	},

	RewardTypes: {
		BONUSCODE:      'BONUSCODE',
		BONUSCODE_VIP:  'bonuscode_vip',
		BONUS:          'bonus',
		ONTOP:          'ontop',
		ONTOP_FLAT:     'ontopflat',
		ONTOP_GIFT:     'ontopgift',
		VIDEO:          'video',
		LIVESHOW:       'liveshow',
		TICKETSHOW:     'ticketshow',
		VIP_BONUS:      'vipBonus',
		VIP_ABO:        'vipAbo',
		CHAT_ROSES:     'chatRoses',
		MERCH:          'merch',
		MESSENGER_GIFT: 'messengerGift',
		PHOTO_ALBUM:    'photoAlbum',
		VIDEO_VOUCHER:  'videoVoucher',
		FREE_SPINS:     'freeSpins',
		FREE_MESSAGES:  'freeMessages',
        BLANK:          'blank',
        FREE_PREVIEW:   'freePreview'
	},

	CalendarItemType: {
		VIDEO:              'video',
		GALLERY:            'photo_album',
		COUPON:             'coupon',
		MESSENGER_GIFTS:    'messengerGifts',
		MERCHANDISE_COUPON: 'merchandise',
	},

	ScrollAnchors: {
		ANCHOR: 'anchor',
		TOP:    'top',
	},

	Sedcards: {
		SEDCARDS_ID: 'sedcards',
	},

	SpecialEvents: {
		NEWSLETTER_UNSUBSCRIBE: 'newsletter_unsubscribe',
		SPECIAL_VR_PORN:        'vr_porn',
		SPECIAL_KICK_IT_2024:   'kick_it_2024',
		SPECIAL_SLOT_MACHINE:   'slot_machine',
		SPECIAL_WIESN_SHOOTING: 'wiesn_shooting',
		SPECIAL_WET_AND_WILD:   'wet_and_wild',
		SPECIAL_WIESN_BUDE:     'wiesn_bude',
		SPECIAL_HALLOWEEN:      'halloween',
		SPECIAL_CUM_ON:         'cum_on'
	},

	SpecialEventsBanner: {
		HALLOWEEN:        559,
		HALLOWEEN_MOBILE: 560,
	},

	StreamTypes: {
		AUDIO:         'audio',
		AUDIO_MP3:     'mp3',
		AUDIO_OGG:     'vorbis',
		UPSTREAM:      'upstream',
		UPSTREAM_JPEG: 'jpeg',
		UPSTREAM_RTMP: 'rtmp',
		VIDEO:         'video',
		VIDEO_JPEG:    'jpeg',
		VIDEO_HLS:     'hls',
		VIDEO_MJPEG:   'mjpeg',
		VIDEO_OGG:     'ogg',
		VIDEO_RTMP:    'rtmp',
		VIDEO_H5LIVE:  'h5live',
	},

	SurveyTypes: {
		CHAT_EXIT: 'SURVEY_CHAT_EXIT',
		FEEDBACK:  'SURVEY_FEEDBACK',
	},

	Teaser: {
		AVS:       'avs',
		PAYMENT:   'payment',
		PROMOCODE: 'promocode',
		VOICECALL: 'voicecall',
		SIGNUP:    'signup',
		VIDEO:     'video',
		MODALBOX:  'modalbox',
	},

	/**
	 * @see EnumTestId
	 */
	TestGroup: {
		EXIT_INTENT: 34,
	},

	/**
	 * @see EnumTestId
	 */

	TileGrid: {
		BOUGHT_ALBUMS:                     'boughtAlbums',
		BOUGHT_ONLY_ALBUMS:                'boughtOnlyAlbums',
		GIFTED_ONLY_ALBUMS:                'giftedOnlyAlbums',
		BOUGHT_VIDEOS:                     'boughtVideos',
		BOUGHT_ONLY_VIDEOS:                'boughtOnlyVideos',
		GIFTED_ONLY_VIDEOS:                'giftedOnlyVideos',
		CATEGORIES:                        'categories',
		CATEGORIES_NEW:                    'categoriesNew',
		CATEGORY_CONTENT:                  'categoryContent',
		CATEGORIES_TOP_CAMS:               'categoriesTopCams',
		CATEGORIES_TOP_CAMS_BEST_CHATS:    'categoriesBestChats',
		CATEGORIES_TOP_CAMS_HD_CAMS:       'categoriesTopHDCams',
		CATEGORIES_TOP_CAMS_TOP_FAVORITES: 'categoriesTopFavorites',
		CONTESTS_ACTIVE_CONTESTS:          'contestsActiveContests',
		CONTESTS_NEXT_CONTESTS:            'contestsNextContests',
		CONTESTS_LAST_CONTESTS:            'contestsLastContests',
		FAVORITE_ACTORS:                   'favoriteActors',
		FAVORITE_CATEGORIES:               'favoriteCategories',
		LATEST_ALBUMS:                     'latestAlbums',
		LIMITED_VIDEOS:                    'limitedVideos',
		MAGAZINE_MAGAZINES_CURRENT:        'magazineMagazineCurrent',
		MAGAZINE_MAGAZINES_OTHERS:         'magazineMagazineOthers',
		MAGAZINE_MAGAZINES_RANDOM:         'magazineMagazineRandom',
		MY_VIDEOCHATS:                     'myVideoChats',
		PINBOARD_PHOTOS:                   'pinboardPhotos',
		PINBOARD_ALBUMS:                   'pinboardAlbums',
		PINBOARD_VIDEOS:                   'pinboardVideos',
		RECOMMENDATIONS_ACTORS:            'recommendationsActors',
		RECOMMENDATIONS_ALBUMS_SIMILAR:    'recommendationsAlbumsSimilar',
		RECOMMENDATIONS_BEST_CHATS:        'recommendationsBestChats',
		RECOMMENDATIONS_VIDEOS:            'recommendationsVideos',
		RECOMMENDATIONS_VIDEOS_SIMILAR:    'recommendationsVideosSimilar',
		SPECIAL_FATHERS_DAY:               'specialFathersDay',
		TOP_CATEGORIES:                    'topCategories',
		TV_ALL_SERIES:                     'tvAllSeries',
		TV_ALL_VIDEOS:                     'tvAllVideos',
		TV_EPISODES:                       'tvEpisodes',
		TV_GIRLS:                          'tvGirls',
		TV_POSTS:                          'tvPosts',
		TV_LATEST_SERIES:                  'tvLatestSeries',
		TV_LATEST_SERIES_VIDEOS:           'tvLatestSeriesVideos',
		TV_LATEST_VIDEOS:                  'tvLatestVideos',
		TV_RECOMMENDED_VIDEOS:             'tvRecommendedVideos',
		TV_RECOMMENDED_VIDEOS_ALL:         'tvRecommendedVideosAll',
		TV_SHOWS:                          'tvShows',
		TV_STARS:                          'tvStars',
		CATEGORIES_HOMEPAGE:               'categoriesHomepage',
	},

	TileGridModes: {
		GRID: 'TILEGRID_MODE_GRID',
		LIST: 'TILEGRID_MODE_LIST',
	},

	TileGridSorts: {
		DURATION: 'duration',
		FREE:     'free',
		PRICE:    'price',
		RATING:   'rating',
		RELEASED: 'released',
		SOLD:     'sold',
	},

	TileGridBreakpoints: {
		FiveCols:  1199,
		FourCols:  991,
		ThreeCols: 767,
	},

	TimeLimits: {
		LIVE_PREVIEW:       5,
		LIVE_PREVIEW_VIP:   20,
		OCP_INCHAT:         60,
		TEXTCHAT_COUNTDOWN: 60,

		Types: {
			PREVIEW: 'preview',
			TEXT:    'text',
			VIDEO:   'video',
		},
	},

	VXMobile: {
		ActorProfilePages: {
			ABOUT:    'ACTOR_PROFILE_ABOUT',
			OVERVIEW: 'ACTOR_PROFILE_OVERVIEW',
			PICTURES: 'ACTOR_PROFILE_PICTURES',
			VIDEOS:   'ACTOR_PROFILE_VIDEOS',
		},

		Menus: {
			NAVI:            'MENU_NAVI',
			SEARCH:          'MENU_SEARCH',
			CAM_FILTER:      'MENU_CAM_FILTER',
			CATEGORY_FILTER: 'MENU_CATEGORY_FILTER',
			MY_VISIT_X:      'MENU_MY_VISIT_X',
			NOTIFICATIONS:   'MENU_NOTIFICATIONS',

			Navigation: {
				LANGUAGE_SELECTOR: 'NAVIGATION_MENU_LANGUAGE_SELECTOR',
			},

			Search: {
				SEARCH_RESULT: 'SEARCH_MENU_SEARCH_RESULT',
			},
		},

		FavoriteActorsPerPage:    10,
		HiddenNotificationTypes:  [6, 7, 13, 14, 15, 22],
		MinSearchPatternLength:   3,
		RecommendationsPerGrid:   8,
		RecommendationsPerSlider: 5,
		VideoItemsPerGrid:        16,
	},

	VXPay: {
		Environments: {
			VXONE_LP: 'vxonelp',
		},
		Flows:        {
			AVS:             'avs',
			LIMIT:           'limit',
			LOGIN:           'login',
			MONEY_CHARGE:    'moneycharge',
			OP_COMPENSATION: 'opcompensation',
			PASSWORD_RESET:  'pwdreset',
			PROMOCODE:       'promocode',
			SCRATCH_CARD:    'scratchcard',
			TRIAL_VIP_ABO:   'trialvipabo',
			VIP_ABO:         'vipabo',
			VXTV_ABO:        'vxtvabo',
		},
	},

	Vxql: {
		Videos:   {
			SortOrders: {
				NEWEST:         'newest',
				MOST_EXPENSIVE: 'mostExpensive',
				CHEAPEST:       'cheapest',
				LONGEST:        'longest',
				SHORTEST:       'shortest',
				BEST_SELLING:   'bestSelling',
				TOP_RATED:      'topRated',
				TOP_TRENDING:   'topTrending',
				RANDOM:         'random',
				REDUCED_RANDOM: 'reducedRandom',
				RATING:         'rating',
				BEST:           'best',
			},

			ActingGroups: {
				FEMALES:  'females',
				MALES:    'males',
				COUPLES:  'couples',
				LESBIANS: 'lesbians',
				GAYS:     'gays',
			},
		},
		Pictures: {
			Sort: {
				NEWEST:         'newest',
				CHEAPEST:       'cheapest',
				TOP_RATED:      'topRated',
				BEST_SELLING:   'bestSelling',
				MOST_EXPENSIVE: 'mostExpensive',
			},
		},
		Search:   {
			Order: {
				NEWEST:    'newest',
				RELEVANT:  'relevant',
				OLDEST:    'oldest',
				PURCHASES: 'purchases',
				BEST:      'best',
				WORST:     'worst',
				SHORTEST:  'shortest',
				LONGEST:   'longest',
				RATING:    'rating',
				LIKES:     'likes',
				EXPENSIVE: 'expensive',
				CHEAP:     'cheap',
			},
		},

		Collection: {
			Order: {
				ALPHABETICAL: 'alphabetical',
				VIEWS:        'views',
			},
		},

		MediathekVideos: {
			ContentGroups: {
				ANSAGEN: 'ansagen',
				SERIE:   'serie',
				SPOT:    'spot',
				TALK:    'talk',
				TEASER:  'teaser',
				TRAILER: 'trailer',
				LOGO:    'logo',
			},
			FormatKey:     {
				LATENIGHT: 'latenight',
			},
		},
		Actors:          {
			Order:      {
				ONLINE_RANKING: 'online_ranking',
				RANKING:        'ranking',
				DATE:           'date',
			},
			Presets:    {
				NEW_GIRLS: 'newGirls'
			},
			Categories: {
				NEW_GIRLS: 'newGirls'
			}
		}
	},

	Websocket: {

		ErrorCodes: {
			PROTOCOL_ERROR:  1002,
			PONG_IS_MISSING: 4000,
		},

		ErrorTypes: {
			CONNECTION_CLOSED: 'WEBSOCKET_ERROR_CONNECTION_CLOSED',
			PONG_IS_MISSING:   'WEBSOCKET_ERROR_PONG_IS_MISSING',
		},

		Types: {
			BOOMER: 'Boomer',
		},
	},

	WebPush: {
		Permissions: {
			GRANTED: 'granted',
			DENIED:  'denied',
			DEFAULT: 'default',
		},
	},

	Video: {
		Categories:   {
			ALL:         'VideosAll',
			NEWEST:      'VideosNew',
			TRENDING:    'VideosTrending',
			FREE_WEEKLY: 'VideosFreeClips',
			TV_SERIES:   'VideosTvSeries',
			ON_SALE:     'VideosOnSale',
		},
		CategoriesV2: {
			ALL_VIDEOS:        'allVideos',
			DISCOUNTED_VIDEOS: 'discountedVideos',
			TRENDING_VIDEOS:   'trendingVideos',
			FREE_VIDEOS:       'freeVideos',
			TOP_VIDEOS:        'topVideos',
			VIP_30_VIDEOS:     'vip30Videos',

		},
		Jumbotron:    {
			TYPE_HOMEPAGE:      'homepage',
			TYPE_VIDEO_STATION: 'videoStation',
		},
	},

	ProSearchResults: {
		GRID_PREFIX: 'pro-search--',
	},

	DailyBonuses: {
		offers:      {
			VIP:            'VipOneDay',
			VIDEO:          'video',
			PHOTO_ALBUM:    'photo_album',
			TOP_UP_PERCENT: 'TopUpPercent',
			TOP_UP_FLAT:    'TopUpFlat',
			MERCHANDISE:    'Merchandise',
			MESSENGER_GIFT: 'MessengerGift',
			VIDEO_VOUCHER:  'VideoVoucher',
		},
		timerConsts: {
			BERLIN_GMT:       +1,
			TIMER_INTERVAL:   1000, // change every second
			DAY_DATE_HOURS:   23, // in the day 24 hours but on the clock we will never see 24:00 it should be 00:00. Therefore, we use 23 hours and 60 minutes
			DAY_DATE_MINUTES: 59,
			DAY_DATE_SECONDS: 59,
		},
		signature:   {
			OFFER_VIP:      'LeahObscure & AlissaNoir',
			TOP_UP_PERCENT: 'JuicyLou',
			TOP_UP_FLAT:    'MimiSweet',
			MESSENGER_GIFT: 'Lilly-Lil',
			VIDEO_VOUCHER:  'KiraKane',
		},
		offerBgs:    {
			VIP:            '/assets/img/special_events/Dailybonus/backgrounds/365specials_img_onedayvip_leahobscure_alissanoir.jpg',
			TOP_UP_PERCENT: '/assets/img/special_events/Dailybonus/backgrounds/365specials_img_vipbonus_JuicyLou.jpg',
			TOP_UP_FLAT:    '/assets/img/special_events/Dailybonus/backgrounds/365specials_img_bonus_mimisweet.jpg',
			MERCHANDISE:    '/assets/img/special_events/Dailybonus/backgrounds/365specials_img_merchshop.jpg',
			MESSENGER_GIFT: '/assets/img/special_events/Dailybonus/backgrounds/365specials_img_messengergift_lillylil.jpg',
			VIDEO_VOUCHER:  '/assets/img/special_events/Dailybonus/backgrounds/365specials_img_videovoucher_kirakane.png',
		},
	},

	VoucherTypes: {
		TYPE_VIDEO:          1,
		TYPE_VIDEOCHAT:      2,
		TYPE_CREDITS:        3,
		TYPE_PHOTO_ALBUM:    4,
		TYPE_MESSAGES:       5,
		TYPE_MESSENGER_GIFT: 6,
		TYPE_LIVE_SHOW:      7,
		TYPE_PRIVATE_SHOW:   8,
		TYPE_BONUS_CODE:     9,
        TYPE_SPINS:         10,
	},

	BenefitsType: {
		BONUS:          'BenefitBonus',
		BONUS_CREDIT:   'BenefitBonusCredits',
		VIP_ABO:        'BenefitVIPAbo',
		PREMIUM_ABO:    'BenefitPremiumAbo',
		ACTOR_ABO:      'BenefitActorAbo',
		FREE_MESSAGES:  'BenefitFreeMessages',
		FREE_MINUTES:   'BenefitFreeMinutes',
		MEDIA:          'BenefitMedia',
		VOUCHER_VIDEO:  'BenefitVoucherVideo',
		VOUCHER_PHOTO:  'BenefitVoucherPhoto',
		LIVE_SHOW:      'BenefitLiveShow',
		MESSENGER_GIFT: 'BenefitMessengerGifts',
	},

	VoucherTypeNames: {
		TYPE_VIDEO:       "videos",
		TYPE_VIDEOCHAT:   "chatSeconds",
		TYPE_PHOTO_ALBUM: "galleries",
		TYPE_MESSAGES:    "messages",
	},

	SearchResults: {
		Categories: {
			actors:    {
				name:         'actors',
				itemsPerPage: 30,
			},
			models:    {
				name:         'models',
				itemsPerPage: 30,
			},
			videos:    {
				name:         'videos',
				itemsPerPage: 30,
			},
			photos:    {
				name:         'photos',
				itemsPerPage: 14,
			},
			galleries: {
				name:         'galleries',
				itemsPerPage: 14,
			},
			tv:        {
				name:         'tv',
				itemsPerPage: 30,
			},
			mediathek: {
				name:         'mediathek',
				itemsPerPage: 30,
			},
			blog:      {
				name:         'blog',
				itemsPerPage: 15,
			},
		},
	},

	ChatRatings: {
		LAST_NINETY_DAYS: 'lastNinetyDays',
	},

	Pincall:          {

		ErrorCodes: {
			INVALID_PHONE_NUMBER:      1,
			COUNTRY_NOT_ALLOWED:       2,
			INVALID_VERIFICATION_TYPE: 3,
			VERIFICATION_TIME_OUT:     4,
			PHONE_NUMBER_IN_BLACKLIST: 5,
			PHONE_NUMBER_NOT_UNIQUE:   6,
			ID_DOES_NOT_EXIST:         7,
			INVALID_CODE:              8,
			ATTEMPT_LIMIT_REACHED:     9,
			UNKNOWN:                   10,
		},

		VerificationTypes: {
			AVS:                       'avs',
			PAYMENT:                   'payment',
			PRIMARY_FIXED_NUMBER:      'primaryFixedNumber',
			PRIMARY_MOBILE_NUMBER:     'primaryMobileNumber',
			SERVICE0900_TARGET_NUMBER: 'service0900TargetNumber',
			UNIQUE_NUMBER:             'uniqueNumber',
		},

		Languages: {
			DE: 'DE',
			EN: 'EN',
			ES: 'ES'
		},

        PinCallLength: {
            MOBILE: 6,
            FIXED:  5,
        }
	},
	MessengerContent: {
		BITMAP: 'Bitmap',
		VIDEO:  'Video',
		AUDIO:  'Audio',
		ALL:    'All',
	},
	VXMessenger:      {
		Modes:     {
			WEBTOKEN: 'webtoken',
			SERVER:   'server',
		},
		ChatTypes: {
			LIVECHAT:            'LIVECHAT',
			VOYEUR_CHAT:         'VOYEUR_CHAT',
			FREECHAT:            'FREECHAT',
			LIVEPREVIEW:         'LIVE_PREVIEW',
			VOYEUR_2S_CHAT:      'VOYEUR_PRIVATE_CHAT',
			STARTMOBILECALLCHAT: 'STARTMOBILECALLCHAT',
			VIDEOCALL:           'VIDEO_CALL'
		},
	},
	Headlines:        {
		h1: 'h1',
		h2: 'h2',
		h3: 'h3',
		h4: 'h4',
	},

	Feed:    {
		FeedType:     {
			PROFILE:  'profile',
			MIXED:    'mixed',
			FAVORITE: 'favorite'
		},
		Types:        {
			CLIP:             "CLIP",
			HOT_CLIP:         "HOT_CLIP",
			FP_HOT_CLIP:      "FP_HOT_CLIP",
			PHOTO_GALLERY:    "PHOTO_GALLERY",
			PHOTOS:           "PHOTOS",
			FP_PHOTO_GALLERY: "FP_PHOTO_GALLERY",
			PROFILE_IMAGE:    "PROFILE_IMAGE",
			TEXT:             "TEXT",
            CONTEST:          "CP_PHOTO_CONTEST"
		},
		OverlayTypes: {
			ALL:      'all',
			IMAGES:   'images',
			COMMENTS: 'comments'
		},
		Filter:       {
			BOUGHT:       'BOUGHT',
			TEXT_POSTS:   'TEXT_POSTS',
			PHOTOS_POSTS: 'PHOTOS_POSTS',
			CLIP_POSTS:   'CLIP_POSTS',
			LIKED:        'LIKED',
			COMMENTED:    'COMMENTED'
		},
		Mode:         {
			STARTPAGE:            'STARTPAGE',
			MIXED:                'MIXED',
			MODEL:                'MODEL',
            USER_RECOMMENDATIONS: 'USER_RECOMMENDATIONS'
		}
	},
	ActorId: {
		LUCY_CAT:     5613624,
		LANA_GREY:    12135526,
		LEXY_ROXX:    3926584,
		LENI_FETISCH: 10327862,
        LIAENGEL:     10641720,
	},
	Legal:   {
		Types: {
			TOS: 'TOS',
			PP:  'PP', // Privacy Policy VX
			GTC: 'GTC', // General Terms and Contidions VX
			TOU: 'TOU',
			IMP: 'IMP' // Imprint VX
		}
	},
	Loader:  {
		Types: {
			LOGO:   'logo',
			DOT:    'dot',
			CIRCLE: 'circle',
			NONE:   'none'
		}
	}
};

export default Constants;
